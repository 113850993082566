import React from "react";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";

export default function ImageLoader({ cardImg }) {
  const [isLoading, setIsLoading] = useState(true);
  const ensureProtocol = (url) => {
    return url?.startsWith("http://") || url?.startsWith("https://")
      ? url
      : `${process.env.REACT_APP_IMAGE_Link}${url}`;
  };

  return (
    <>
      {isLoading ? <Skeleton count={1} height="90px" /> : null}
      <img
        src={`${cardImg ? ensureProtocol(cardImg) : ""}`}
        className="card_logo_img"
        alt="card pic"
        style={isLoading ? { display: "none" } : {}}
        onLoad={() => {
          setIsLoading(false);
        }}
      />
    </>
  );
}
