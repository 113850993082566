import React from "react";
import { Link, useLocation } from "react-router-dom";

import { Helmet } from "react-helmet";

export default function Sidebar() {
  const locate = useLocation();

  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_API_SITE_TITLE} Admin</title>
      </Helmet>

      <div id="sidebar" className="collapse collapse-horizontal show ">
        <div
          id="sidebar-nav"
          className="list-group border-0 rounded-0 text-sm-start min-vh-100"
        >
          <Link
            to="/admin/dashboard"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/admin/dashboard" ? "active" : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-house-fill"></i> <span> Dashboard</span>{" "}
          </Link>
          {/* <Link
            to="/admin/pavlina-index"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/admin/pavlina-index" ? "active" : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-house-fill"></i> <span> Pavlina</span>{" "}
          </Link> */}

          <Link
            to="/admin/import-file"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/admin/import-file" ? "active" : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-filetype-doc"></i> <span> Manage Document</span>{" "}
          </Link>
          <Link
            to="/admin/oos-emails"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/admin/oos-emails" ? "active" : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-envelope-arrow-up"></i>{" "}
            <span> Upload OOS Emails</span>{" "}
          </Link>
          <Link
            to="/admin/manual-orders"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/admin/manual-orders" ? "active" : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-bag-check"></i> <span> Manual Orders</span>{" "}
          </Link>

          {/* <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/manage-document" ? "active" : ""
            }`}
            data-bs-toggle="collapse"
            href="#Document"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/import-file" ? "true" : "false"
            }
            aria-controls="Document"
          >
            <i className="bi bi-filetype-doc"></i> <span> Manage Document</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/import-file" ? "show" : ""
            }`}
            id="Document"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/import-file"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/import-file" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Import File</span>{" "}
            </Link>
          </div> */}

          <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/manage-website" ||
              locate.pathname === "/admin/manage-newsletter"
                ? "active"
                : ""
            }`}
            data-bs-toggle="collapse"
            href="#Website"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/manage-website" ||
              locate.pathname === "/admin/manage-newsletter"
                ? "true"
                : "false"
            }
            aria-controls="Website"
          >
            <i className="bi bi-globe"></i> <span> Manage Website</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/manage-website" ||
              locate.pathname === "/admin/manage-newsletter"
                ? "show"
                : ""
            }`}
            id="Website"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/manage-website"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/manage-website" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Manage Website</span>{" "}
            </Link>
            <Link
              to="/admin/manage-newsletter"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/manage-newsletter" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Manage Newsletter</span>{" "}
            </Link>
          </div>

          <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/add-products" ||
              locate.pathname === "/admin/manage-products" ||
              locate.pathname === "/admin/stanley-deactivated-products"
                ? "active"
                : ""
            }`}
            data-bs-toggle="collapse"
            href="#products"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/add-products" ||
              locate.pathname === "/admin/manage-products" ||
              locate.pathname === "/admin/stanley-deactivated-products"
                ? "true"
                : "false"
            }
            aria-controls="products"
          >
            <i className="bi bi-gift"></i> <span> Manage Products</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/add-products" ||
              locate.pathname === "/admin/manage-products" ||
              locate.pathname === "/admin/stanley-deactivated-products"
                ? "show"
                : ""
            }`}
            id="products"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/add-products"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/add-products" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span>Add Product</span>{" "}
            </Link>
            <Link
              to="/admin/manage-products"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/manage-products" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span>Manage Products</span>{" "}
            </Link>
            {/* <Link
              to="/admin/stanley-deactivated-products"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/stanley-deactivated-products"
                  ? "active"
                  : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span>Stanley Deactivated Products</span>{" "}
            </Link> */}
          </div>

          <Link
            to="/admin/orders"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/admin/orders" ? "active" : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-cart2"></i> <span> Manage Orders</span>{" "}
          </Link>

          {/* <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/orders" ||
              locate.pathname === "/admin/archOrder"
                ? "active"
                : ""
            }`}
            data-bs-toggle="collapse"
            href="#orders"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/orders" ||
              locate.pathname === "/admin/archOrder"
                ? "true"
                : "false"
            }
            aria-controls="orders"
          >
            <i className="bi bi-cart2"></i> <span> Manage Orders</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/orders" ||
              locate.pathname === "/admin/archOrder"
                ? "show"
                : ""
            }`}
            id="orders"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/orders"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/orders" ||
                locate.pathname === "/admin/archOrder"
                  ? "active"
                  : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> View All Orders</span>{" "}
            </Link>
          </div> */}

          <Link
            to="/admin/vouchers"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/admin/vouchers" ? "active" : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-card-heading"></i> <span> Manage Vouchers</span>{" "}
          </Link>
          {/* <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/vouchers" ||
              locate.pathname === "/admin/used-vouchers"
                ? "active"
                : ""
            }`}
            data-bs-toggle="collapse"
            href="#vouchers"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/vouchers" ||
              locate.pathname === "/admin/used-vouchers"
                ? "true"
                : "false"
            }
            aria-controls="vouchers"
          >
            <i className="bi bi-card-heading"></i> <span> View Vouchers</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/vouchers" ||
              locate.pathname === "/admin/used-vouchers"
                ? "show"
                : ""
            }`}
            id="vouchers"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/vouchers"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/vouchers" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Show Vouchers</span>{" "}
            </Link>
            <Link
              to="/admin/used-vouchers"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/used-vouchers" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Show Used Vouchers</span>{" "}
            </Link>
          </div> */}

          <Link
            to="/admin/manage-job"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/admin/manage-job" ? "active" : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-list-task"></i> <span> Manage Jobs</span>{" "}
          </Link>
          {/* <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/manage-job" ? "active" : ""
            }`}
            data-bs-toggle="collapse"
            href="#job"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/manage-job" ? "true" : "false"
            }
            aria-controls="job"
          >
            <i className="bi bi-list-task"></i> <span> Manage Jobs</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/manage-job" ? "show" : ""
            }`}
            id="job"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/manage-job"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/manage-job" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> View All Jobs</span>{" "}
            </Link>
          </div> */}

          <a
            className={`list-group-item border-end-0 d-inline-block text-truncate has-arrow ${
              locate.pathname === "/admin/add-email" ||
              locate.pathname === "/admin/manage-email"
                ? "active"
                : ""
            }`}
            data-bs-toggle="collapse"
            href="#email"
            role="button"
            aria-expanded={
              locate.pathname === "/admin/add-email" ||
              locate.pathname === "/admin/manage-email"
                ? "true"
                : "false"
            }
            aria-controls="email"
          >
            <i className="bi bi-envelope-at"></i> <span> Manage Email</span>{" "}
          </a>
          <div
            className={`collapse ${
              locate.pathname === "/admin/add-email" ||
              locate.pathname === "/admin/manage-email"
                ? "show"
                : ""
            }`}
            id="email"
            data-bs-parent="#sidebar-nav"
          >
            <Link
              to="/admin/add-email"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/add-email" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> Add Email</span>{" "}
            </Link>
            <Link
              to="/admin/manage-email"
              className={`list-group-item border-end-0 d-inline-block text-truncate w-100 ${
                locate.pathname === "/admin/manage-email" ? "active" : ""
              }`}
              data-bs-parent="#sidebar"
            >
              <span> View Email</span>{" "}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
